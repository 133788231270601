var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"g-nav"},[_c('div',{staticClass:"bars-con",on:{"click":_vm.collapse}},[_c('div',{staticClass:"bars"},[_c('div',{class:[
          'menu-item',
          'menu-o',
          _vm.actived === 1 ? 'active' : '',
          _vm.actived === 0 ? 'normal' : '' ]}),_c('div',{class:[
          'menu-item',
          'menu-t',
          _vm.actived === 1 ? 'active' : '',
          _vm.actived === 0 ? 'normal' : '' ]}),_c('div',{class:[
          'menu-item',
          'menu-th',
          _vm.actived === 1 ? 'active' : '',
          _vm.actived === 0 ? 'normal' : '' ]})])]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapsed),expression:"collapsed"}],staticClass:"g-shadow",on:{"click":_vm.close,"touchmove":_vm.forbiddnScroll}})]),_c('div',{staticClass:"g-nav-normal"},[_c('div',{staticClass:"g-nav-content"},[_c('div',{staticClass:"g-nav-bar"},[_c('div',{staticClass:"g-nav-bar-logo",style:(_vm.logoStlNormal)}),_c('div',{staticClass:"g-nav-menu"},[_c('p',{staticClass:"user",on:{"click":_vm.toUser}},[_c('i')])])])])]),_c('div',{class:[
      'g-nav-active',
      _vm.dep === 'banma' ? 'banma' : '',
      _vm.actived === 1 ? 'active' : '',
      _vm.actived === 0 ? 'normal' : '',
      _vm.actived === '' ? 'initialization' : '' ],on:{"touchmove":_vm.forbiddnScroll}},[_c('div',{staticClass:"g-nav-content"},[_c('div',{staticClass:"g-nav-bar"},[_c('div',{class:[
            'g-nav-bar-logo',
            _vm.actived === 1 ? 'active' : '',
            _vm.actived === 0 ? 'normal' : '' ],style:(_vm.logoStlAct)})]),_c('ul',{class:[
          'g-nav-list',
          _vm.actived === 1 ? 'active' : '',
          _vm.actived === 0 ? 'normal' : '' ]},_vm._l((_vm.options),function(item){return _c('li',{key:item.page,on:{"click":function($event){return _vm.positioning(item.id)}}},[_vm._v(" "+_vm._s(item.page)+" ")])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }