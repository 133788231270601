const cityList = [
  '武汉',
  '西安',
  '郑州',
  '南京',
  '成都',
  '长沙',
  '沈阳',
  '济南',
  '天津',
  '重庆',
  '长春',
  '合肥',
]
const cityListBanma = [
  '武汉',
  '西安',
  '郑州',
  '南京',
  '成都',
  '长沙',
  '天津',
  '济南',
  '重庆',
]
const cList = [
  {
    img: require('../../assets/img/c-1.png'),
    address: '武汉市光谷新发展国际中心B座',
    name: '武汉',
    englishName: 'WUHAN',
  },
  {
    img: require('../../assets/img/c-2.png'),
    address: '武汉市光谷金融港B14座',
    name: '武汉',
    englishName: 'WUHAN',
  },
  {
    img: require('../../assets/img/c-3.png'),
    address: '西安市雁塔区团结南路11号中晶科技广场18-22层',
    name: '西安',
    englishName: 'XIAN',
  },
  {
    img: require('../../assets/img/c-4.png'),
    address: '西安市曲江新区汇新路大夏国际中心B座14-22层',
    name: '西安',
    englishName: 'XIAN',
  },
  {
    img: require('../../assets/img/c-5.png'),
    address: '南京市建邺区泰山路189号万得大厦B座13层15层',
    name: '南京',
    englishName: 'NANJING',
  },
  {
    img: require('../../assets/img/c-6.png'),
    address: '河南省郑州市金水区花园路39号招银大厦23—27楼',
    name: '郑州',
    englishName: 'ZHENGZHOU',
  },
  {
    img: require('../../assets/img/c-16.jpg'),
    address: '河南省郑州市金水区绿地双子塔南塔59-62层',
    name: '郑州',
    englishName: 'ZHENGZHOU',
  },
  {
    img: require('../../assets/img/c-7.png'),
    address: '沈阳市沈河区青年大街茂业中心38楼',
    name: '沈阳',
    englishName: 'SHENYANG',
  },
  {
    img: require('../../assets/img/c-8.jpg'),
    address: '济南市历下区经十路11001号中国人寿大厦北楼16层',
    name: '济南',
    englishName: 'JINAN',
  },
  {
    img: require('../../assets/img/c-88.jpg'),
    address: '山东省济南市历下区经十路11111号华润中心共5层',
    name: '济南',
    englishName: 'JINAN',
  },
  {
    img: require('../../assets/img/c-10.png'),
    address: '湖南省长沙市芙蓉区五一国金中心IFS-t1写字楼（共11层）',
    name: '长沙',
    englishName: 'CHANGSHA',
  },
  {
    img: require('../../assets/img/c-11.png'),
    address: '成都高新区天府大道北段28号茂业中心C座13层',
    name: '成都',
    englishName: 'CHENGDU',
  },
  {
    img: require('../../assets/img/c-18.jpg'),
    address: '成都市高新区剑南大道716号瑞鑫时代大厦11-14层',
    name: '成都',
    englishName: 'CHENGDU',
  },
  {
    img: require('../../assets/img/c-17.jpg'),
    address: '重庆市渝中区接圣街8号来福士写字楼A座11-16层',
    name: '重庆',
    englishName: 'CHONGQING',
  },
  {
    img: require('../../assets/img/c-13.jpg'),
    address: '吉林省长春市二道区自由大路3999号第一国际中心共四层',
    name: '长春',
    englishName: 'CHANGCHUN',
  },
  {
    img: require('../../assets/img/c-14.jpg'),
    address: '天津市和平区南京路与赤峰道交口天津国际金融中心58-60F',
    name: '天津',
    englishName: 'TIANJIN',
  },
  {
    img: require('../../assets/img/c-15.png'),
    address: '安徽省合肥市蜀山区政务区华润大厦A座33-34层',
    name: '合肥',
    englishName: 'HEFEI',
  },
]
const cListBanma = [
  {
    img: require('../../assets/img/cc-1.jpg'),
    address: '西安市雁塔区曲江新区汇新路大夏国际中心B座21层',
    name: '西安',
    englishName: 'XIAN',
  },
  {
    img: require('../../assets/img/cc-2.jpg'),
    address: '西安市雁塔区曲江新区环球国际中心23层',
    name: '西安',
    englishName: 'XIAN',
  },
  {
    img: require('../../assets/img/cc-3.jpg'),
    address: '成都市高新区天府大道北段28号茂业中心c座13层',
    name: '成都',
    englishName: 'CHENGDU',
  },
  {
    img: require('../../assets/img/cc-5.jpeg'),
    address: '武汉市江夏区光谷金融港B6栋',
    name: '武汉',
    englishName: 'WUHAN',
  },
  {
    img: require('../../assets/img/cc-6.jpg'),
    address: '武汉市现代光谷世贸中心世贸生活汇创客星1楼',
    name: '武汉',
    englishName: 'WUHAN',
  },
  {
    img: require('../../assets/img/c-8.jpg'),
    address: '济南市历下区经十路中国人寿大厦北楼17层',
    name: '济南',
    englishName: 'JINAN',
  },
  {
    img: require('../../assets/img/cc-7.jpg'),
    address: '济南市历下区草山岭南路金域万科中心A栋16层',
    name: '济南',
    englishName: 'JINAN',
  },
  {
    img: require('../../assets/img/cc-8.jpg'),
    address: '郑州市管城回族区商鼎路78号升龙广场3号楼A座3404',
    name: '郑州',
    englishName: 'ZHENGZHOU',
  },
  {
    img: require('../../assets/img/cc-9.jpg'),
    address: '郑州市郑东新区东风南路与创业路交叉口绿地中心南塔54层',
    name: '郑州',
    englishName: 'ZHENGZHOU',
  },
  {
    img: require('../../assets/img/cc-10.jpg'),
    address: '长沙市岳麓区梅溪湖环湖路1177号金茂广场北塔38层',
    name: '长沙',
    englishName: 'CHANGSHA',
  },
  {
    img: require('../../assets/img/cc-11.jpg'),
    address: '重庆市渝北区恒大中渝广场1号写字楼13层',
    name: '重庆',
    englishName: 'CHONGQING',
  },
  {
    img: require('../../assets/img/cc-12.jpeg'),
    address: '重庆市江北区国金中心T1写字楼32层',
    name: '重庆',
    englishName: 'CHONGQING',
  },
  {
    img: require('../../assets/img/cc-13.jpg'),
    address: '天津市红桥区陆家嘴金融广场b座5层',
    name: '天津',
    englishName: 'TIANJIN',
  },
  {
    img: require('../../assets/img/cc-15.png'),
    address: '江苏省南京市建邺区集庆门大街268号苏宁睿城E06幢29-33层',
    name: '南京',
    englishName: 'NANJING',
  },
]
const workImage = [
  {
    img: require('../../assets/img/w-1.png'),
    name: '出游合照',
  },
  {
    img: require('../../assets/img/w-2.png'),
    name: '生日会',
  },
  {
    img: require('../../assets/img/w-3.png'),
    name: '月会',
  },
  {
    img: require('../../assets/img/w-4.png'),
    name: '团建合照',
  },
]
const envImage = [
  {
    img: require('../../assets/img/e-1.png'),
  },
  {
    img: require('../../assets/img/e-2.png'),
  },
  {
    img: require('../../assets/img/e-3.png'),
  },
  {
    img: require('../../assets/img/e-4.png'),
  },
]
const banner = [
  {
    img: require('../../assets/img/b1.png'),
  },
  {
    img: require('../../assets/img/b2.png'),
  },
]
const dynamicImage = [
  {
    img: require('../../assets/img/b1-banma.png'),
    url: 'https://mp.weixin.qq.com/s/h3gCtUxKDOd1RZ6D4DztPw',
  },
  {
    img: require('../../assets/img/b2-banma.png'),
    desc: '斑马“十三五”教育部重点课题开题发布会',
    url:
      'https://mp.weixin.qq.com/s?__biz=MzU4MTgyMzQ3NQ==&mid=2247484523&idx=1&sn=3bcb4d21c139c0c33b7b6cb5ca0b7feb&chksm=fd40faf7ca3773e18ebeb52b553fc09d42102bfd3f5ab6fca75e87e61f28c941bcf80637aab1&token=651122675&lang=zh_CN#rd',
  },
  {
    img: require('../../assets/img/b3-banma.png'),
    desc: '斑马登上央视频“春暖花开国聘行动”全回顾',
    url:
      'https://mp.weixin.qq.com/s?__biz=MzU4MTgyMzQ3NQ==&mid=2247484510&idx=1&sn=a49052049cae06480470bae1d10ba006&chksm=fd40fac2ca3773d49c73e2cea25aac1beb489ba6722cb27f432d0c331c84357ce2b41eb683be&token=651122675&lang=zh_CN#rd',
  },
]
/**
 * @func
 * @desc 判断数据类型
 * @param o 需要判断类型的数据
 * @returns boolean
 */
const type = o => {
  let s = Object.prototype.toString.call(o)
  return s.match(/\[object (.*?)\]/)[1].toLowerCase()
}
;[
  'Undefined',
  'Null',
  'String',
  'Number',
  'Boolean',
  'Object',
  'Array',
  'Function',
  'RegExp',
].forEach(t => {
  type['is' + t] = o => {
    return type(o) === t.toLowerCase()
  }
})
const merge = () => {}
/**
 * @func
 * @desc 判断设备
 * @param
 * @returns boolean
 */
const browser = () => {
  const u = navigator.userAgent
  return {
    isIPhone: u.indexOf('iPhone') > -1, // 是否为iPhone
    isAndroid: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
    isWeixin: /micromessenger/i.test(u), //微信
    isQQ: /MQQBrowser/i.test(u) && /\sQQ/i.test(u), // QQ
    isQQBrowser: /MQQBrowser/i.test(u) && !/\sQQ/i.test(u), //qq浏览器
    isUCBrowser: /UCBrowser/i.test(u), //uc浏览器
    isSafari:
      u.indexOf('Safari') > -1 &&
      u.indexOf('Chrome') <= -1 &&
      u.indexOf('CriOS') <= -1 &&
      u.indexOf('FxiOS') <= -1 &&
      u.indexOf('MQQBrowser') <= -1, // safari浏览器
    isPc: !/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(u),
    isQiyeWeixin: /micromessenger/i.test(u) && /wxwork/i.test(u),
  }
}
/**
 * @func
 * @desc 按照size分割数组
 * @param
 * @returns boolean
 */
const chunk = (arr, size) => {
  if (!type.isArray(arr) || size <= 0) {
    return []
  }
  const len = Math.ceil(arr.length / size)
  const result = []
  let idx = 0
  while (idx < len) {
    result[idx] = arr.slice(idx * size, (idx + 1) * size)
    idx++
  }
  return result
}
/**
 * @func
 * @desc 滚动到顶部
 * @param
 * @returns boolean
 */
const top = () => {
  try {
    document.scrollingElement.scrollTop = 0
    document.body.scrollTop = document.documentElement.scrollTop = 0
  } catch (error) {}
}

const params = function() {
  try {
    if (location.hash) {
      let query = location.hash.split('?')[1] || ''
      let paramsObj = {}
      let params = query ? query.split('&') : []
      params.forEach(item => {
        let [key, val] = item.split('=')
        paramsObj[key] = val
      })
      return paramsObj
    }
    return {}
  } catch (error) {}
}
const download = (url, fileName, needEncode) => {
  let encodeUri = needEncode ? encodeURI(url) : url
  const a = document.createElement('a')
  a.href = encodeUri
  if (fileName) {
    a.download = fileName
  }
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export {
  cityList,
  cityListBanma,
  cList,
  cListBanma,
  workImage,
  envImage,
  banner,
  dynamicImage,
  type,
  merge,
  browser,
  chunk,
  top,
  params,
  download,
  getBase64,
}
