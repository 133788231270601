<template>
  <div class="g-nav">
    <div class="bars-con" @click="collapse">
      <div class="bars">
        <div
          :class="[
            'menu-item',
            'menu-o',
            actived === 1 ? 'active' : '',
            actived === 0 ? 'normal' : '',
          ]"
        ></div>
        <div
          :class="[
            'menu-item',
            'menu-t',
            actived === 1 ? 'active' : '',
            actived === 0 ? 'normal' : '',
          ]"
        ></div>
        <div
          :class="[
            'menu-item',
            'menu-th',
            actived === 1 ? 'active' : '',
            actived === 0 ? 'normal' : '',
          ]"
        ></div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="collapsed"
        @click="close"
        @touchmove="forbiddnScroll"
        class="g-shadow"
      ></div>
    </transition>
    <div class="g-nav-normal">
      <div class="g-nav-content">
        <div class="g-nav-bar">
          <div class="g-nav-bar-logo" :style="logoStlNormal"></div>
          <div class="g-nav-menu">
            <p class="user" @click="toUser">
              <i></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      @touchmove="forbiddnScroll"
      :class="[
        'g-nav-active',
        dep === 'banma' ? 'banma' : '',
        actived === 1 ? 'active' : '',
        actived === 0 ? 'normal' : '',
        actived === '' ? 'initialization' : '',
      ]"
    >
      <div class="g-nav-content">
        <div class="g-nav-bar">
          <div
            :class="[
              'g-nav-bar-logo',
              actived === 1 ? 'active' : '',
              actived === 0 ? 'normal' : '',
            ]"
            :style="logoStlAct"
          ></div>
        </div>
        <ul
          :class="[
            'g-nav-list',
            actived === 1 ? 'active' : '',
            actived === 0 ? 'normal' : '',
          ]"
        >
          <li
            v-for="item in options"
            :key="item.page"
            @click="positioning(item.id)"
          >
            {{ item.page }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import logoYuanfudao from '@/assets/img/logo.png'
import logoBanma from '@/assets/img/logo-banmaaike.png'
import { mapState, mapActions } from 'vuex'
import { browser, params } from '@/assets/js'
const { keyfrom = 'wechat-official' } = params()
export default {
  name: 'navBar',
  components: {},
  props: {
    options: {
      require: true,
      type: Array,
    },
  },
  data() {
    return { logoStlNormal: {}, logoStlAct: {} }
  },
  computed: {
    ...mapState(['collapsed', 'actived', 'dep']),
  },
  created() {
    this.themeSwitch()
  },
  mounted() {},

  methods: {
    ...mapActions(['setCollapsed', 'setActived']),
    themeSwitch() {
      if (this.dep === 'yuanfudao') {
        this.logoStlNormal = {
          width: '0.69rem',
          height: '0.18rem',
          backgroundImage: `url(${logoYuanfudao})`,
        }
        this.logoStlAct = {
          width: '0.69rem',
          height: '0.18rem',
          margin: '0.13rem 0 0 0.16rem',
          backgroundImage: `url(${logoYuanfudao})`,
        }
      } else if (this.dep === 'banma') {
        this.logoStlNormal = {
          width: '0.62rem',
          height: '0.22rem',
          backgroundImage: `url(${logoBanma})`,
        }
        this.logoStlAct = {
          width: '0.62rem',
          height: '0.22rem',
          margin: '0.115rem 0 0 0.16rem',
          backgroundImage: `url(${logoBanma})`,
        }
      }
    },
    positioning(id) {
      this.close()
      const path = this.$route.path
      const { isIPhone, isUCBrowser } = browser()
      let gNavHeight =
        getComputedStyle(document.querySelector('.g-nav')).height || 50
      gNavHeight = parseFloat(gNavHeight) || 50
      if (path === '/home') {
        if (!(isIPhone && isUCBrowser)) {
          $('html, body').animate(
            { scrollTop: $(`#${id}`).offset().top - gNavHeight - 12 },
            500,
          )
        } else {
          const oTop = document.getElementById(`${id}`).offsetTop
          $('.g-main').animate({ scrollTop: oTop - 100 }, 500)
        }
      } else {
        this.$router.push({ path: '/home', query: { keyfrom } })
        if (!(isIPhone && isUCBrowser)) {
          setTimeout(function() {
            $('html, body').animate(
              { scrollTop: $(`#${id}`).offset().top - gNavHeight - 12 },
              500,
            )
          }, 500)
        } else {
          setTimeout(function() {
            const oTop = document.getElementById(`${id}`).offsetTop
            $('.g-main').animate({ scrollTop: oTop - 100 }, 500)
          }, 500)
        }
      }
    },
    toUser() {
      this.frogReport('/click/mRecurimentHome/toUser')
      const { path } = this.$route
      if (path === '/myprogress') {
        location.reload()
        return
      }
      this.$router.push({
        path: '/myprogress',
        query: { keyfrom },
      })
    },
    collapse() {
      this.setCollapsed(!this.collapsed)
      this.setActived(Number(!this.actived))
    },
    close() {
      this.setCollapsed(false)
      this.setActived(0)
    },
    forbiddnScroll(e) {
      try {
        e.preventDefault()
      } catch (error) {}
    },
    frogReport(url, extra = []) {
      this.$addFrog(1531, url, [...extra])
    },
  },
}
</script>
<style lang="less" scoped>
.g-nav {
  background: #fff;
  position: fixed;
  z-index: 4;
  width: 100%;
  max-width: 770px;
  .g-shadow {
    position: fixed;
    z-index: 3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  .bars-con {
    position: absolute;
    right: 0.04rem;
    top: 0;
    width: 0.42rem;
    height: 0.44rem;
    .bars {
      position: absolute;
      top: 0.16rem;
      right: 0.14rem;
      z-index: 1000;
      transform: scale(0.3);
      transform-origin: right top;
      .menu-item {
        margin: 0 auto;
        width: 0.586667rem;
        border-top: 0.055rem solid #323232;
        transform-origin: left;
        &:not(:last-child) {
          margin-bottom: 0.16rem;
        }
      }
      .menu-o {
        &.active {
          animation: menuoIn 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
        &.normal {
          animation: menuoOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
      }
      .menu-t {
        &.active {
          animation: menutIn 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
        &.normal {
          animation: menutOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
      }
      .menu-th {
        &.active {
          animation: menuthIn 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
        &.normal {
          animation: menuthOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
      }
    }
  }

  .g-nav-active {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 5;
    overflow: hidden;
    &.active {
      display: block;
      animation: navIn 0.3s ease-in-out 0s forwards;
    }
    &.normal {
      animation: navOut 0.3s ease-in-out 0.2s forwards;
    }
    &.active.banma {
      display: block;
      animation: navInBanma 0.3s ease-in-out 0s forwards;
    }
    &.normal.banma {
      animation: navOutBanma 0.3s ease-in-out 0.2s forwards;
    }
    &.initialization {
      height: 0;
    }
    .g-nav-bar {
      height: 0.44rem;
      display: flex;
      justify-content: space-between;
      .g-nav-bar-logo {
        .bgS();
        display: flex;
        &.active {
          animation: logoIn 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0s forwards;
        }
        &.normal {
          animation: logoOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0.2s forwards;
        }
      }
    }
    .g-nav-list {
      background: #fff;
      overflow: hidden;
      li {
        height: 0.7rem;
        line-height: 0.7rem;
        padding-right: 0.2rem;
        text-align: right;
        color: #333;
        font-weight: bold;
        font-size: 0.18rem;
      }
      &.active {
        li:nth-child(1) {
          animation: navItemIn 0.8s cubic-bezier(0.2, 0, 0.2, 1) 0.11s forwards;
        }
        li:nth-child(2) {
          animation: navItemIn 0.6s cubic-bezier(0.2, 0, 0.2, 1) 0.08s forwards;
        }
        li:nth-child(3) {
          animation: navItemIn 0.4s cubic-bezier(0.2, 0, 0.2, 1) 0.05s forwards;
        }
        li:nth-child(4) {
          animation: navItemIn 0.2s cubic-bezier(0.2, 0, 0.2, 1) forwards;
        }
      }
      &.normal {
        li:nth-child(1) {
          animation: navItemOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) forwards;
        }
        li:nth-child(2) {
          animation: navItemOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0.05s forwards;
        }
        li:nth-child(3) {
          animation: navItemOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0.08s forwards;
        }
        li:nth-child(3) {
          animation: navItemOut 0.3s cubic-bezier(0.2, 0, 0.2, 1) 0.11s forwards;
        }
      }
    }
  }
  .g-nav-normal {
    position: relative;
    .g-nav-bar {
      height: 0.44rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .g-nav-bar-logo {
        margin-left: 0.16rem;
        .bgS();
      }
      .g-nav-menu {
        margin-right: 0.46rem;
        .user {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.42rem;
          height: 0.44rem;
        }
        .user i {
          width: 0.22rem;
          height: 0.22rem;
          .bgS();
          .bgI('../assets/img/user.png');
        }
      }
    }
  }
}
@media screen and (min-width: 770px) {
  .g-nav {
    .user,
    .bars,
    li {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 770px) {
  .g-nav {
    left: 0;
  }
}
</style>
<style lang="less">
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
@keyframes navIn {
  0% {
    height: 0;
    display: none;
  }
  100% {
    height: 2.54rem;
  }
}
@keyframes navOut {
  0% {
    height: 2.54rem;
  }
  100% {
    height: 0;
    display: none;
  }
}
@keyframes navInBanma {
  0% {
    height: 0;
    display: none;
  }
  100% {
    height: 3.24rem;
  }
}
@keyframes navOutBanma {
  0% {
    height: 3.24rem;
  }
  100% {
    height: 0;
    display: none;
  }
}
@keyframes logoIn {
  0% {
    height: 0;
  }
  100% {
    height: '.21rem';
  }
}
@keyframes logoOut {
  0% {
    height: '.21rem';
  }
  100% {
    height: 0;
  }
}
@keyframes menuoIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes menuoOut {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes menutIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes menutOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menuthIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes menuthOut {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes navItemIn {
  0% {
    opacity: 0;
    transform: translateY(-0.666667rem) rotateX(-40deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotateX(0deg);
  }
}
@keyframes navItemOut {
  0% {
    opacity: 1;
    transform: translateY(0) rotateX(0deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-0.666667rem) rotateX(-40deg);
  }
}
</style>
