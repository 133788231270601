import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { queryCityList } from '@/service/index'
let getCityLock = false
import { cityList, cityListBanma } from '@/assets/js'
export default new Vuex.Store({
  state: {
    city: [],
    collapsed: false,
    actived: '',
    dep: 'yuanfudao',
    department: '猿辅导',
    title: '',
    desc: '',
    themeColor: '',
  },
  mutations: {
    SET_CITY: (state, value) => {
      state.city = value
    },
    SET_COLLAPSED: (state, value) => {
      state.collapsed = value
    },
    SET_ACTIVED: (state, value) => {
      state.actived = value
    },
    SET_DEP: (state, value) => {
      state.dep = value
    },
    SET_DEPARTMENT: (state, value) => {
      state.department = value
    },
    SET_T_COlOR: (state, value) => {
      state.themeColor = value
    },
    SET_TIT: (state, value) => {
      state.title = value
    },
    SET_DESC: (state, value) => {
      state.desc = value
    },
  },
  actions: {
    getCity: async ({ commit, state }, dep) => {
      if (!getCityLock && !state.city.length) {
        getCityLock = true
        let city = dep === 'yuanfudao' ? [...cityList] : [...cityListBanma]
        const query =
          dep === 'yuanfudao'
            ? {
                recruitmentWebsiteId: 1,
                department: '猿辅导',
                teachType: '辅导老师',
              }
            : {
                recruitmentWebsiteId: 2,
                department: '斑马',
                teachType: '辅导老师',
              }
        try {
          const asyncCity = await queryCityList(query)
          const cityNames = asyncCity.map(item => {
            return item.cityName
          })
          cityNames.forEach(item => {
            if (!city.includes(item)) city.push(item)
          })
          city = city.map(item => {
            const itemCopy = { cityName: item, id: '' }
            asyncCity.forEach(aItem => {
              if (aItem.cityName === item) {
                itemCopy.id = aItem.id
              }
            })
            return itemCopy
          })
        } catch (error) {
          console.log(console.error)
        }
        commit('SET_CITY', city)
        getCityLock = false
      }
    },
    setCollapsed: ({ commit, state }, data) => {
      commit('SET_COLLAPSED', data)
    },
    setActived: ({ commit, state }, data) => {
      commit('SET_ACTIVED', data)
    },
    setDep: ({ commit, state }, data) => {
      commit('SET_DEP', data)
      if (data === 'yuanfudao') {
        commit('SET_T_COlOR', '#FF6C00')
        commit('SET_DEPARTMENT', '猿辅导')
        commit('SET_TIT', '猿辅导辅导老师招聘官网')
        commit('SET_DESC', '发现改变世界的猿动力')
      } else if (data === 'banma') {
        commit('SET_T_COlOR', '#34C83F')
        commit('SET_DEPARTMENT', '斑马')
        commit('SET_TIT', '斑马AI课辅导老师招聘 | 来斑马，做不一般的你')
        commit(
          'SET_DESC',
          '全国九城联招，有竞争力的薪资+超多培训+完备晋升+丰厚福利，超棒offer在斑马等你！',
        )
      }
    },
  },
})
