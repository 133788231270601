<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'app',
  created() {
    this.initializeTheme()
    this.$wechatInit()
  },
  methods: {
    ...mapActions(['setDep']),
    initializeTheme() {
      const pathname = document.location.pathname
      if (pathname.includes('banma')) {
        this.setDep('banma')
      } else {
        this.setDep('yuanfudao')
      }
    },
  },
}
</script>
<style lang="less">
#app {
  margin: 0 auto;
  max-width: 770px;
}
</style>
