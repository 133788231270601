import axios from 'axios'
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
function goLogin() {
  const authServer = 'https://account.zhenguanyu.com/login'
  const authServerUrl = `${authServer}?service=${encodeURIComponent(
    location.href,
  )}`
  location.replace(authServerUrl)
}

function goLogout() {
  location.replace(
    `https://account.zhenguanyu.com/logout?from=` +
      encodeURIComponent(location.href),
  )
}

const codeMessage = {
  OFF_LINE: '抱歉，您貌似还没连接到网络，请检查网络连接',
  200: '服务器成功返回请求的数据',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '抱歉，您貌似还没有登录',
  403: '抱歉，您没有权限访问该页面',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
}

let instance = axios.create()

function createError(responseError) {
  console.log('responseError', responseError)
  let [code, errorMsg, response] = ['', '', {}]
  if (axios.isCancel(responseError)) {
    // 主动取消的请求
    console.warn(responseError.message)
    return
  }

  // 请求已经发送，并且服务器有返回
  if (responseError.response || responseError.status) {
    response = responseError.response || responseError
    // 错误码\错误信息
    code = response.status
    errorMsg = response.data.message || response.data.msg
  } else if (responseError.request) {
    // 请求已发送但是没有收到服务器响应
    if ('onLine' in navigator && navigator.onLine === false) {
      code = 'OFF_LINE'
    } else if (responseError.code === 'ECONNABORTED') {
      code = 504
      errorMsg = `网络请求超时(${responseError.config.timeout}ms)`
    }
  } else {
    // 请求未发出
    errorMsg = responseError
  }
  // 未登录，跳转到登陆页
  if (code === 401) {
    goLogin()
  }
  if (!code) {
    code = -1
  }
  if (!errorMsg) {
    errorMsg =
      codeMessage[code] || response.statusText || `抱歉，当前请求异常(${code})`
  }
  Toast(errorMsg)

  const error = new Error(errorMsg)
  error.name = code
  error.message = errorMsg
  error.response = response
  return Promise.reject(errorMsg)
}

instance.interceptors.request.use(
  ({
    timeout = 60 * 1000,
    retry = 2,
    retryDelay = 1000,
    withCredentials = true,
    ...rest
  }) => {
    return {
      timeout,
      retry,
      retryDelay,
      withCredentials,
      ...rest,
    }
  },
)

instance.interceptors.response.use(
  ({ data: { data, code, msg, message }, config: { method } }) => {
    if (code === 1) {
      if (method !== 'get') {
        // Toast('操作成功')
      }
      return data
    } else {
      return Promise.reject(msg || message)
    }
  },
  createError,
)

let ossHost = 'http://garden-h5-test.yuanfudao.biz'
let apiHost = 'garden-test.zhenguanyu.com'
let H5UploadHost = 'garden-h5-test.yuanfudao.biz'
let frogUrl = 'https://frog.yuanfudao.com/statV2/plain'
if (location.hostname.includes('bole')) {
  apiHost = 'bole-test.zhenguanyu.com'
  H5UploadHost = 'bole-test.zhenguanyu.com'
  if (location.hostname === 'bole.zhenguanyu.com') {
    console.log(location.hostname)
    apiHost = 'bole.zhenguanyu.com'
    H5UploadHost = 'bole.zhenguanyu.com'
  }
} else if (location.hostname === 'zhaopin.yuanfudao.com') {
  apiHost = 'garden.yuanfudao.com'
  ossHost = 'https://garden.yuanfudao.com'
  H5UploadHost = 'garden.yuanfudao.com'
  frogUrl = 'https://frog.yuanfudao.com/statV2/plain'
}
export default function request(
  url,
  { method = 'get', body = {} } = {},
  config,
) {
  method = method.toLowerCase()
  const requestUrl = `${window.location.protocol}//${apiHost}${url}`
  return instance[method](requestUrl, body, (config = {}))
    .then(res => res)
    .catch(err => Promise.reject(err))
}
export { goLogin, goLogout, ossHost, apiHost, H5UploadHost, frogUrl }
