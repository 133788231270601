import request from './request'
import { stringify } from 'qs'
/**
 * 获取职位列表
 * @param {Object} query
 * @param {String} query.cityId
 * @param {String} query.recruitmentWebsiteId
 * @param {Number} query.flag
 * @param {Number} query.type
 * @param {String} query.teachType
 * @param {String} query.positionName
 */
export function queryPosition(query) {
  const qs = stringify(query)
  return request(`/ep-garden-position/recruitment_website/position/list?${qs}`)
}

export function getToken(url, department) {
  return request(
    `/ep-garden/wechat/jssdk_signature?url=${url}&department=${department}`,
  )
}

export function queryCityList(query) {
  const qs = stringify(query)
  return request(`/ep-garden-position/internal/website/city/list?${qs}`)
}
