import Frog from '@fenbi/frog'
import { frogUrl } from '@/service/request'
import { params, browser } from '@/assets/js'
import wx from 'weixin-js-sdk'
import { getToken } from '@/service'
import store from '@/store/index'
import imgUrl from '../img/b1.png'
import imgUrlBanma from '../img/wechat-banma.png'
const { isWeixin } = browser()
const { keyfrom = 'wechat-official' } = params()
const install = Vue => {
  Vue.prototype.$addFrog = function(productId, url, keyValues = []) {
    keyValues = keyValues.concat([
      { key: 'keyfrom', value: keyfrom },
      { key: 'department', value: store.state.dep },
    ])
    const ins = new Frog({
      url: frogUrl,
      maxCount: 10,
      productId: productId,
      keyValues,
    })
    ins.add(url)
  }
  Vue.prototype.$wechatInit = async () => {
    try {
      if (isWeixin) {
        let url = location.href.split('#')[0]
        url = encodeURIComponent(url)
        const res = await getToken(url, store.state.department)
        const { appId, timestamp, noncestr, signature } = res
        wx.config({
          debug: false,
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature, // 必填，签名，见附录1
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
        })
        wx.error(function(res) {
          console.log('wx.error', res)
        })
      }
    } catch (error) {
      console.log('_error', error)
    }
  }
  Vue.prototype.$wechatShare = async function(info) {
    if (isWeixin) {
      wx.ready(() => {
        console.log('wx.ready')
        wx.checkJsApi({
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
            const { errMsg = '', checkResult = {} } = res
            if (
              errMsg === 'checkJsApi:ok' &&
              checkResult &&
              checkResult.updateAppMessageShareData === true &&
              checkResult.updateTimelineShareData === true
            ) {
              const { title, desc, link } = info
              wx.updateAppMessageShareData({
                title, // 分享标题
                desc, // 分享描述
                link, // 分享链接
                imgUrl: `${
                  store.state.dep === 'yuanfudao' ? imgUrl : imgUrlBanma
                }`, // 分享图标
              })
              wx.updateTimelineShareData({
                title, // 分享标题
                link, // 分享链接
                imgUrl: `${
                  store.state.dep === 'yuanfudao' ? imgUrl : imgUrlBanma
                }`, // 分享图标
              })
            }
          },
        })
      })
    }
  }
}

export default { install }
