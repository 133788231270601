;(function flexible(window, document) {
  var docEl = document.documentElement

  // set 1rem = viewWidth / 10
  function setRemUnit() {
    var clientWidth = docEl.clientWidth
    var rem = clientWidth > 600 ? 100 : (clientWidth / 375) * 100
    docEl.style.fontSize = rem + 'px'
  }

  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function(e) {
    if (e.persisted) {
      setRemUnit()
    }
  })
})(window, document)
