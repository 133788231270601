import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
Vue.use(VueRouter)
import store from '@/store'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/Home.vue'),
      },
      {
        path: 'position',
        name: 'position',
        component: () => import('@/views/Position/Position.vue'),
      },

      {
        path: 'myprogress',
        name: 'myprogress',
        component: () => import('@/views/Mypgs/Mypgs.vue'),
      },
      {
        path: 'officialDelivery',
        name: 'officialDelivery',
        component: () =>
          import('@/views/OfficialDelivery/OfficialDelivery.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
router.afterEach((to, from, next) => {
  try {
    store.dispatch('setCollapsed', false)
    store.dispatch('setActived', '')
  } catch (error) {}
})
export default router
