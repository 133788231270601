<template>
  <div>
    <header>
      <g-nav-bar :options="options" />
    </header>
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import GNavBar from '@/components/GNavBar'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'index',
  components: { GNavBar },
  data() {
    return {}
  },
  computed: {
    ...mapState(['dep']),
    options() {
      if (this.dep === 'yuanfudao') {
        return [
          { page: '主页', id: 'home' },
          { page: '岗位介绍', id: 'position' },
          { page: '公司介绍', id: 'about' },
        ]
      } else {
        return [
          { page: '主页', id: 'home' },
          { page: '岗位介绍', id: 'position' },
          { page: '公司介绍', id: 'about' },
          { page: '城市地址', id: 'city' },
        ]
      }
    },
  },
}
</script>
<style lang="less">
header {
  height: 0.44rem;
}
</style>
